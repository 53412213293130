import React, { Component } from 'react';
import './Footer.css'
class Footer extends Component {
    render() {
        return (
        <div className="footer">
            This site was last updated on {this.props.date}.
        </div>
        );
    }
}

export default Footer;