import './SectionContainer.css'
import React, { Component } from "react";

class SectionContainer extends Component {
 render() {
   return <div className="SectionContainer">
    <div className="SectionContainer-header">
        <h2>{this.props.title}:</h2>
    </div>
    <div className="SectionContainer-content">
    {
        this.props.content.map((e)=>{
            return <SectionSubcontainer image={e.image} content={e.content} title={e.title} date={e.date} />
        })
    }
    </div>
   </div>;
 }
}

class SectionSubcontainer extends Component {
    render () {
        return <div className='SectionSubcontainer'>
            <div className="SectionsSubcontainer-image">
                <img src={this.props.image} alt="" />
            </div>
            <div className="SectionSubcontainer-content">
                <h3>{this.props.title}</h3>
                <i>{this.props.date}</i>
                {this.props.content}
            </div>
        </div>
    }
}

export { SectionContainer, SectionSubcontainer }