import React, { Component } from 'react';

class PageNotFound extends Component {
    
    render() {
        return (
            <div>
                Oops.... looks like this page can not be found.
            </div>
        );
    }
}

export default PageNotFound;